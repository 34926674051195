<template>
  <section v-if="permiso">
    <section fluid>
      <v-row>
        <v-col cols="12" sm="6">
          <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>

      <v-row no-gutters class="d-flex align-center">
        <v-col cols="12" sm="2">
          <v-img
            class="mx-auto mx-sm-0"
            width="130px"
            src="@/assets/img/logo_512_negro.png"
          />
        </v-col>
        <v-col
          cols="12"
          sm="10"
          md="7"
          class="text-center text-sm-start my-6 my-sm-0"
        >
          <h4 class="text-h6 text-sm-h4">{{ procesoData?.nombre_proceso }}</h4>
          <p
            v-if="cdf?.validacion && !haveRole('ROLE_PROVEEDOR')"
            class="secondary--text body-2 mb-0 mt-4"
          >
            <v-icon color="success">mdi-check-decagram</v-icon>
            Fondo de proceso certificados
          </p>
          <p
            v-if="!cdf?.validacion && !haveRole('ROLE_PROVEEDOR')"
            class="secondary--text body-2 mb-0 mt-4"
          >
            <v-icon color="gray">mdi-alert-decagram</v-icon>
            Se han certificado
            <b>{{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              }).format(cdf?.cantidades?.monto_certificado)
            }}</b>
            de
            <b>{{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              }).format(cdf?.cantidades?.monto_requerido)
            }}</b>
          </p>
        </v-col>

        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column"
          v-if="![7].includes(procesoData.id_estado_proceso)"
        >
          <v-btn
            class="text-no-style mx-auto"
            color="secondary"
            :to="{
              name: 'pac-procesos-configuracion',
              params: { idProceso: $route.params.idProceso },
            }"
            v-if="
              haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO']) &&
              ![4, 5, 6, 8, 9].includes(procesoData.id_estado_proceso)
            "
          >
            Configuración del proceso
            <v-icon color="white">mdi-open-in-new</v-icon>
          </v-btn>
          <v-btn
            color="secondary"
            @click="modalConfirmarParticipacion = true"
            v-if="
              procesoData?.id_seguimiento_proceso === 3 &&
              haveRoles(['ROLE_PROVEEDOR']) &&
              !this.haveRole('ROLE_SUPER_ADMIN') &&
              !this.participando
            "
          >
            Participar
          </v-btn>
        </v-col>
      </v-row>

      <CambioEstadoProceso
        v-if="
          procesoData.id_estado_proceso === 5 &&
          haveRoles(['ROLE_CAMBIO_ESTADO_PROCESO_VIEW'])
        "
        :id-proceso="procesoData.id"
        @on-cambio-estado="getProceso()"
      />

      <!-- EJECUCIÓN DE PROCESO DE COMPRA -->
      <v-row
        v-if="
          ![7].includes(procesoData.id_estado_proceso) ||
          haveRole('ROLE_AUDITORIA_PROCESO')
        "
      >
        <v-col cols="12">
          <v-tabs v-model="id" background-color="bgMinsal" show-arrows>
            <v-tab
              v-for="(item, index) in contenido"
              :key="index"
              class="secondary--text"
              style="text-transform: none !important"
              v-if="!item.hide"
            >
              <v-icon>{{ item.icon }}</v-icon>
              <span class="pl-1">{{ item.tab }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="id">
            <v-tab-item
              v-for="(item, index) in contenido"
              :key="index"
              v-if="!item.hide"
            >
              <component
                :is="item.content"
                :proceso="procesoData"
                class="mt-8"
                :getProceso="getProceso"
              ></component>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <!-- EJECUCIÓN DE PROCESO DE IMPUGNACIONES -->
      <v-row v-if="procesoData.id_estado_proceso === 7">
        <v-col cols="12">
          <ProcesoImpugnacionesComponent />
        </v-col>
      </v-row>

      <ConfirmationDialogComponent
        :show="modalConfirmarParticipacion"
        btnConfirmar="Continuar"
        :title="`¿Desea inscribirse en el proceso?`"
        message="Esta acción es irreversible después de realizada"
        @close="modalConfirmarParticipacion = false"
        @confirm="inscripcionProceso()"
      />
    </section>
  </section>

  <section class="mt-16" v-else-if="cargando === true">
    <v-row justify="center" class="mb-8">
      <v-col cols="12" sm="6" md="4" align="center">
        <v-img src="../../assets/img/comprasal_login.png" />
      </v-col>
    </v-row>
    <h3 class="text-center">Cargando información...</h3>
  </section>

  <section class="mt-16" v-else-if="perfilCompleto === false">
    <v-row justify="center" class="mb-8">
      <v-col cols="12" sm="6" md="4" align="center">
        <v-img src="../../assets/img/comprasal_login.png" />
      </v-col>
    </v-row>
    <h3 class="text-center">
      Debe completar su perfil para observar los procesos vigentes
    </h3>
  </section>
</template>
<script>
import EtapaActualComponent from "./components/EtapaActualComponent.vue";
import InfoGeneralComponent from "./components/InfoGeneralComponent.vue";
import PIPComponent from "./components/PIPComponent.vue";
import ParticipantesComponent from "./components/ParticipantesComponent.vue";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import DocumentsProccessComponent from "./components/DocumentsProccessComponent.vue";
import DocProveedorComponent from "./components/DocProveedorComponent.vue";
import ProcesoImpugnacionesComponent from "./RecursosRevision/ProcesoImpugnacionesComponent.vue";
import MainAuditoriaComponent from "./AuditoriaProcesos/MainAuditoriaComponent.vue";
import ContainerAdminComponent from "./components/ContainerAdminComponent.vue";
import EtapaActualModificativaLibre from "./components/EtapaActualModificativaLibre.vue";

import { mapState, mapMutations } from "vuex";
import CambioEstadoProceso from "./components/CambioEstadoProceso.vue";
import { Observable, Listener } from "@/utils/observable.js";

export default {
  name: "etapasProcesoView",
  components: {
    EtapaActualComponent,
    InfoGeneralComponent,
    PIPComponent,
    ParticipantesComponent,
    ConfirmationDialogComponent,
    DocumentsProccessComponent,
    DocProveedorComponent,
    ProcesoImpugnacionesComponent,
    MainAuditoriaComponent,
    ContainerAdminComponent,
    CambioEstadoProceso,
  },
  data: () => ({
    cargando: true,
    perfilCompleto: false,
    proceso: {},
    id: null,
    modalPublicarProceso: false,
    modalConfirmarParticipacion: false,
    cdf: { cantidades: null, validacion: null },
    unsubscribe: null,
    es_proceso_libre: false,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData", "participando"]),
    contenido() {
      return [
        {
          id: 1,
          tab: "Etapa Actual",
          icon: "mdi-clock-outline",
          content: EtapaActualComponent,
          hide:
            [5, 6, 9].includes(this.procesoData.id_estado_proceso) ||
            [18].includes(this.procesoData.id_forma_contratacion),
        },
        {
          id: 2,
          tab: "General",
          icon: "mdi-file-cog",
          content: InfoGeneralComponent,
        },
        {
          id: 3,
          tab: "Plan de implementación del proceso",
          icon: "mdi-format-list-bulleted",
          content: PIPComponent,
          hide: [27].includes(this.procesoData.id_forma_contratacion),
        },
        {
          id: 4,
          tab: "Documentos de solicitud de oferta",
          icon: "mdi-feature-search-outline",
          content: ContainerAdminComponent,
          hide:
            ![3, 11].includes(this.procesoData.id_seguimiento_proceso) ||
            !this.haveRoles(["ROLE_UACI", "ROLE_UACI_TECNICO"]),
        },
        {
          id: 5,
          tab: "Documentos del proceso",
          icon: "mdi-file-document-multiple",
          content: DocumentsProccessComponent,
          hide:
            !this.haveRoles([
              "ROLE_UACI",
              "ROLE_UACI_TECNICO",
              "ROLE_DIRECCION_COMPRAS",
              "ROLE_TECNICO_DIRECCION_COMPRAS",
              "ROLE_OFICIAL_CUMPLIMIENTO",
              "ROLE_AUDITOR_INTERNO_AUIN",
              "ROLE_PROVEEDOR",
            ]) || [27, 25, 18].includes(this.procesoData.id_forma_contratacion),
        },
        {
          id: 6,
          tab: "Participantes",
          icon: "mdi-account-group",
          content: ParticipantesComponent,
          hide:
            this.haveRoles(["ROLE_PROVEEDOR", "ROLE_TACOP"]) ||
            [27, 25, 18].includes(this.procesoData.id_forma_contratacion),
        },
        // {
        //   id: 7,
        //   tab: "Documentos para el proveedor",
        //   icon: "mdi-file-document-multiple",
        //   content: DocProveedorComponent,
        //   hide: !this.haveRoles([
        //     "ROLE_PROVEEDOR",
        //     "ROLE_UACI",
        //     "ROLE_UACI_TECNICO",
        //     "ROLE_DIRECCION_COMPRAS",
        //     "ROLE_TECNICO_DIRECCION_COMPRAS",
        //     "ROLE_OFICIAL_CUMPLIMIENTO",
        //   ]) || [27, 25].includes(this.procesoData.id_forma_contratacion),
        // },
        {
          id: 8,
          tab: "Auditoría del proceso",
          icon: "mdi-package-variant",
          content: MainAuditoriaComponent,
          hide: !this.haveRoles(["ROLE_SUPER_ADMIN", "ROLE_AUDITORIA_PROCESO"]),
        },
        {
          id: 9,
          tab: "Documentación",
          icon: "mdi-file-document-multiple",
          content: DocProveedorComponent,
          hide: ![27].includes(this.procesoData.id_forma_contratacion),
        },
        {
          id: 10,
          tab: "Cierre de contratos",
          icon: "mdi-file-sign",
          content: EtapaActualModificativaLibre,
          hide: !this.es_proceso_libre,
        },
      ];
    },
    breadcrumbsItems() {
      return [
        {
          text: "Proceso",
          disable: false,
          href: "/pac-procesos",
        },
        {
          text: `${this.procesoData?.codigo_proceso} / ${
            this.procesoData?.codigo_interno || ""
          }`,
          disable: true,
        },
      ];
    },
    permiso() {
      if (
        this.haveRole("ROLE_PROVEEDOR") &&
        !this.haveRole("ROLE_SUPER_ADMIN") &&
        this.perfilCompleto
      ) {
        return true;
      } else if (
        this.haveRole("ROLE_PROVEEDOR") &&
        !this.haveRole("ROLE_SUPER_ADMIN") &&
        this.perfilCompleto === false
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapMutations("procesoCompraDoc", ["setInfoProceso", "setParticipando"]),
    async getProceso() {
      const response = await this.services.PacProcesos.getProceso(
        this.$route.params.idProceso
      ).catch(() => {
        this.$router.replace({ name: "dashboard" });
      });

      if (response.status === 200) this.setInfoProceso(response.data);
    },
    async cambiarEstadoProceso() {
      const response =
        await this.services.PacProcesos.cambioEstadoProcesoCompra(
          this.$route.params.idProceso,
          1
        );

      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "El proceso ha sido actualizado con éxito",
        });
        this.modalPublicarProceso = false;
        this.$router.replace({ name: "pac-procesos" });
      }
    },
    async validarParticipacion() {
      const response = await this.services.PacProcesos.validarParticipacion(
        this.$route.params.idProceso
      );

      if (response.status === 200)
        this.setParticipando(response.data.message || false);
    },
    async inscripcionProceso() {
      const response = await this.services.PacProcesos.inscripcionProceso(
        this.$route.params.idProceso
      );

      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Se ha inscrito en el proceso",
        });
        location.reload();
      }
    },

    async verificarCdfProceso() {
      const response = await this.services.PacProcesos.verificarCdfProceso(
        this.$route.params.idProceso
      );
      if (response.status === 200) {
        this.cdf.cantidades = response.data;

        if (
          response.data?.monto_certificado !== null &&
          response.data?.monto_requerido !== null
        ) {
          this.cdf.validacion =
            response.data?.monto_certificado >= response.data?.monto_requerido;
        }
      }
    },
    async getProfilePercentage() {
      if (
        this.haveRole("ROLE_PROVEEDOR") &&
        !this.haveRole("ROLE_SUPER_ADMIN")
      ) {
        let response = await this.services.Proveedores.getProviderPercentage();
        if (response.status == 200)
          this.perfilCompleto = response?.data.perfil_completo;
        this.cargando = false;
      } else {
        this.cargando = false;
      }
    },
    async getProcesoLibre() {
      const { data } = await this.services.PacProcesos.getProcesoLibre(
        this.$route.params.idProceso
      );
      if (Object.keys(data).length > 0) {
        this.es_proceso_libre = true;
      }
    },
  },
  watch: {
    "procesoData.sacar"(value) {
      if (!!value) {
        this.pushAppMessage({
          message:
            "Este proceso de compra no permite la participación de proveedores",
          type: "info",
          show: true,
        });
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      }
    },
  },
  async created() {
    await this.getProfilePercentage();
    await this.getProceso();
    if (!this.haveRole("ROLE_PROVEEDOR")) {
      await this.getProcesoLibre();
    }
    if (this.haveRole("ROLE_PROVEEDOR") && !this.haveRole("ROLE_SUPER_ADMIN"))
      await this.validarParticipacion();
    if (!this.haveRole("ROLE_PROVEEDOR")) await this.verificarCdfProceso();

    const listener = new Listener("etapasProcesoView").setHandler(() =>
      this.getProceso()
    );
    this.unsubscribe = Observable.getInstance().subscribe(
      "recargar-proceso-actual",
      listener
    );
  },
  beforeDestroy() {
    this.unsubscribe?.();
  },
};
</script>
