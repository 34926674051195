<template>
  <Fragment>
    <template v-if="subastaSeleccionada != null">
      <v-card class="text-center py-4">
        <p v-show="moment(subastaSeleccionada.fecha_hora_inicio).isAfter(
          moment(serverTime, 'x')
        )
          " class="info--text">
          <app-countdown titulo="La subasta iniciará en:" color="info--text" :date-now="moment(serverTime, 'x')"
            :nextDay="moment(subastaSeleccionada.fecha_hora_inicio)" @status="$emit('getSalaInfo', true)" />
        </p>
        <p v-show="moment(subastaSeleccionada.fecha_hora_final).isBefore(
          moment(serverTime, 'x')
        )
          " class="error--text">
          La subasta ha terminado.
        </p>
        <indicacionOfertas :oferta="oferta" />

        <div v-if="
          moment(subastaSeleccionada.fecha_hora_inicio).isBefore(
            moment(serverTime, 'x')
          )">
          <v-alert color="blue-grey" dark dense prominent type="info">
            <span>
              La subasta del lote actual se ha planificado de
              {{ moment(oferta?.subasta?.fecha_hora_inicio).format("HH:mm a") }}
              hasta
              {{ moment(oferta?.subasta?.fecha_hora_final).format("HH:mm a") }}
            </span>
          </v-alert>
          <v-alert type="info" color="#42a5f5" v-show="mensaje_advertencia && !haveRole('ROLE_PROVEEDOR') &&
            !moment(subastaSeleccionada.fecha_hora_final).isBefore(
              moment(serverTime, 'x')
            ) &&
            !moment(subastaSeleccionada.fecha_hora_inicio).isAfter(
              moment(serverTime, 'x')
            )
            ">
            {{ mensajeCuentaAtras }}

          </v-alert>
          <v-alert class="white--text" color="#42a5f5" v-show="mensaje_advertencia && !actualGanador &&
            haveRole('ROLE_PROVEEDOR') &&
            !moment(subastaSeleccionada.fecha_hora_final).isBefore(
              moment(serverTime, 'x')
            ) &&
            !moment(subastaSeleccionada.fecha_hora_inicio).isAfter(
              moment(serverTime, 'x')
            )
            ">
            {{ fechaEventoCierreConfigurado.mensaje }}
          </v-alert>
          <br />
          <app-countdown-segundos v-show="!moment(subastaSeleccionada.fecha_hora_final).isBefore(
            moment(serverTime, 'x')
          ) &&
            !moment(subastaSeleccionada.fecha_hora_inicio).isAfter(
              moment(serverTime, 'x')
            )
            " :nextDay="fechaEventoCierreConfigurado?.tiempo"
            :nuevo_tiempo="fechaEventoCierreConfigurado?.nuevo_tiempo"
            @status="$emit('cierreSala', !fechaEventoCierreConfigurado?.tiempo !== 0)"
            @cierreSala="cambiar_visibilidad_" />
        </div>
        <span class="text-h4 subastaBlue--text">
          {{ subastaSeleccionada.nombre_lote }}
        </span>
        <div class="d-flex flex-column mx-6" :class="actualGanador ? 'ganadorClass elevation-6' : ''" v-if="
          !moment(subastaSeleccionada.fecha_hora_final).isBefore(
            moment(serverTime, 'x')
          )
        ">
          <span v-if="loadingOferta" class="text-h3 font-weight-medium">Cargando...</span>
          <span v-else-if="
            ['resta_monto', 'resta_unitaria'].includes(
              oferta?.subasta?.CtlSubastum.tipo_operacion
            )
          " class="text-h1 font-weight-medium">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(subastaActivaMejorPuja)
            }}
            USD
          </span>
          <span v-else-if="
            ['suma_porcentaje', 'resta_porcentaje_publicidad', 'resta_porcentaje'].includes(
              oferta?.subasta?.CtlSubastum.tipo_operacion
            )
          ">
            <span class="text-h1 font-weight-medium">
              {{ subastaActivaMejorPujaPorcentaje }}%
              <br />
            </span>
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(totalSubasta)
            }}
          </span>
          <span v-if="actualGanador" class="text-h6"> ¡Vas ganando! </span>
        </div>
        <div v-else>
          <v-alert type="info">
            <span class="text-h4"> La subasta ha finalizado </span>
          </v-alert>
        </div>

        <template v-if="
          haveRole('ROLE_FRONT_PROVEEDOR_PROCESO_COMPRA') &&
          moment(subastaSeleccionada.fecha_hora_inicio).isBefore(
            moment(serverTime, 'x')
          )
        ">
          <template v-if="
            !moment(subastaSeleccionada.fecha_hora_final).isBefore(
              moment(serverTime, 'x')
            )
          ">
            <template v-if="
              ['resta_monto', 'resta_unitaria'].includes(
                oferta?.subasta?.CtlSubastum.tipo_operacion
              )
            ">
              <v-col cols="12">
                <div class="d-flex flex-wrap align-center justify-center justify-space-around">
                  <v-tooltip top v-for="(ofertaBid, index) in ofertas" :key="index">
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="loadingOfertar" @click="ofertar(ofertaBid.monto)" :key="index"
                        outlined :color="ofertaBid.color" v-on="on" dark depressed class="my-4 mx-2"
                        :style="`background-color: ${ofertaBid.color}`">
                        <!-- <v-icon color="white" class="mx-2">mdi-minus-circle-outline</v-icon> -->
                        <span class="white--text">
                          {{
                            Intl.NumberFormat("en-US", {
                              currency: "USD",
                              style: "currency",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(ofertaBid?.monto)
                          }}
                        </span>
                      </v-btn>
                    </template>
                    <span v-if="
                      ['resta_unitaria', 'resta_monto'].includes(
                        oferta?.subasta?.CtlSubastum.tipo_operacion
                      )
                    ">
                      Su oferta será por un total de:
                      {{
                        Intl.NumberFormat("en-US", {
                          currency: "USD",
                          style: "currency",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(ofertaBid?.monto * (oferta?.subasta?.cantidad ?? 1))
                      }}
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
              <template>
                <v-row v-if="this.oferta?.subasta?.monto_digitado" justify="center" class="mt-4">
                  <div class="digitar_puja">
                    <v-text-field v-model="montoPuja" rounded outlined
                      placeholder="Digite su puja y presione enter para enviar" @keyup="validarPujaDigitada"
                      @keydown.enter="ofertar(montoPuja, true)">
                      <template v-slot:append>
                        <v-icon @click="ofertar(montoPuja, true)"
                          :disabled="loadingOfertar">mdi-send-variant-outline</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                </v-row>
              </template>
            </template>
            <v-col cols="12" v-else-if="
              [
                'resta_porcentaje',
                'suma_porcentaje',
                'resta_porcentaje_publicidad',
              ].includes(oferta?.subasta?.CtlSubastum.tipo_operacion)
            ">
              <v-text-field v-model="montoPujaPorcentaje" rounded outlined placeholder="Digite su puja"
                @keyup="validarPuja" />
              <v-btn class="subastaBlue--text" @click="enviarPujaPorcentual">
                Enviar puja
                <span class="black--text" v-if="
                  montoPujaPorcentaje &&
                  ['suma_porcentaje'].includes(
                    oferta?.subasta?.CtlSubastum.tipo_operacion
                  )
                ">
                  &nbsp; &nbsp; &nbsp; (equivalente a
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(
                      montoInicial - (montoPujaPorcentaje * montoInicial) / 100
                    )
                  }})
                </span>
              </v-btn>
            </v-col>
          </template>
        </template>
      </v-card>
      <div class="my-8 pt-12 text-center text-h6">
        <span class="subastaBlue--text" v-text="'Monto Inicial: '" />
        <span>
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(montoInicial)
          }}
        </span>
      </div>
    </template>
  </Fragment>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import IndicacionOfertas from "./indiciacionOfertas.vue";
import { Fragment } from "vue-fragment";

export default {
  name: "panelSubasta",
  components: {
    IndicacionOfertas,
    Fragment
  },
  computed: {
    ...mapState(["serverTime"]),
    fechaEventoCierreConfigurado() {
      if (this.oferta?.subasta?.cuenta_atras_puja === null)
        return {
          nuevo_tiempo: false,
          tiempo: 0,
        };

      const tiempo = this.oferta?.subasta?.cuenta_atras_puja
        ? {
          tiempo: this.calcularMilis(
            this.oferta?.subasta?.tiempo_maximo_oferta,
            this.oferta?.fecha_ultima_puja
          ),
          type: "cuenta_atrás_puja",
          mensaje:
            "Si no envía nuevas pujas, en el lote actual se declarará como ganador a la última oferta en:",
        }
        : {
          tiempo: this.calcularMilis(
            this.oferta?.subasta?.tiempo_maximo_desierto
          ),
          type: "cuenta_atrás_desierto",
          as: this.oferta?.subasta?.tiempo_maximo_desierto,
          mensaje:
            "Si el lote a subastar no recibe pujas se declarará como desierta en:",
        };
      return {
        nuevo_tiempo: !!(
          this.oferta?.subasta?.tiempo_maximo_desierto &&
          this.oferta?.subasta?.tiempo_maximo_oferta
        ),
        ...tiempo,
      };
    },
    mensajeCuentaAtras() {
      return this.oferta?.subasta?.cuenta_atras_puja
        ? "Si el lote a subastar no recibe nuevas pujas se declarará como ganador a la última oferta en:"
        : "Si el lote a subastar no recibe pujas se declarará como desierta en:"
    }
  },
  data: () => ({
    mensaje_advertencia: true,
    loadingOfertar: false,
    montoPujaPorcentaje: null,
    montoPuja: null,
    pujasRules: [
      (v) => !!v || "El campo es requerido.",
      (v) => Number.isInteger(+v) || "El campo debe ser de tipo entero.",
      (v) => +v >= 0 || "No debe ser menor que cero.",
      (v) => +v < totalSubasta || "No debe ser mayor que la actual puja .",
    ],
  }),
  methods: {
    cambiar_visibilidad_(bandera = false) {
      this.mensaje_advertencia = bandera;
    },
    calcularMilis(
      duracion,
      fecha_hora_inicio = this.oferta?.subasta?.fecha_hora_inicio,
      fecha_hora_final = this.oferta?.subasta?.fecha_hora_final
    ) {

      const cumpleFormato = /^\d+:\d+:\d+$/i.test(duracion);

      let duracionSegundos = moment.duration(duracion).asSeconds();

      if (cumpleFormato) {
        const [hours, minutes, seconds] = duracion.split(':');
        duracionSegundos = moment.duration({ hours, minutes, seconds }).asSeconds();
      }

      const segundosPasadosDesdeUltimaOferta = this.serverTime.diff(moment(fecha_hora_inicio), 'seconds');

      if (segundosPasadosDesdeUltimaOferta > duracionSegundos) return 0;

      const tiempoRestanteBruto = moment(fecha_hora_final).diff(this.serverTime, 'seconds');
      return Math.min(tiempoRestanteBruto, (duracionSegundos - segundosPasadosDesdeUltimaOferta)) * 1000;
    },
    validarPuja() {
      if (this.montoPujaPorcentaje > 100 || this.montoPujaPorcentaje < 0) {
        this.pushAppMessage({
          show: true,
          type: "info",
          message: "El porcentaje debe ser mayor a 0 y menor a 100",
        });
        const [entero, decimal] = this.montoPujaPorcentaje.split(".");
        this.montoPujaPorcentaje = `${entero?.slice(0, 2)}`;
      }
      if (
        this.montoPujaPorcentaje !== null &&
        this.montoPujaPorcentaje !== undefined
      ) {
        const [entero, decimal] = this.montoPujaPorcentaje.split(".");
        if (decimal && decimal.length > 2) {
          this.pushAppMessage({
            show: true,
            type: "info",
            message: "El porcentaje debe tener máximo 2 decimales",
            s,
          });
          this.montoPujaPorcentaje = `${entero}.${decimal.slice(0, 2)}`;
        }
      }
    },
    async enviarPujaPorcentual() {
      if (!this.montoPujaPorcentaje) return;
      try {
        this.loadingOfertar = true;
        this.pujando = true;
        const {
          data: { proxima_operacion },
        } = await this.services.Paac.postOfertaPorcentual(
          this.subastaSeleccionada.id_subasta,
          {
            porcentaje: Number(this.montoPujaPorcentaje),
          }
        );
        const currentDate = moment(this.serverTime, "x");
        const nextOfferTime = moment(proxima_operacion, "DD/MM/YYYY HH:mm:ss");
        if (nextOfferTime.isAfter(currentDate)) {
          this.timeOferta = moment(
            proxima_operacion,
            "DD/MM/YYYY HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
          this.counting = true;
        } else {
          this.timeOferta = 0;
          this.counting = false;
        }
        this.montoPujaPorcentaje = null;
      } catch (e) {
        this.$emit("recargarSubasta");
      } finally {
        this.loadingOfertar = false;
      }
    },
    async ofertar(monto, digitada = false) {
      if (!monto) return;
      this.loadingOfertar = true;
      this.pujando = true;
      try {
        const {
          data: { proxima_operacion },
        } = await this.services.Paac.postOferta(
          this.subastaSeleccionada.id_subasta,
          {
            monto: Number(monto),
            digitada,
          }
        );
        const currentDate = moment(this.serverTime, "x");
        const nextOfferTime = moment(proxima_operacion, "DD/MM/YYYY HH:mm:ss");
        if (nextOfferTime.isAfter(currentDate)) {
          this.timeOferta = moment(
            proxima_operacion,
            "DD/MM/YYYY HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
          this.counting = true;
        } else {
          this.timeOferta = 0;
          this.counting = false;
        }
      } catch (e) {
        const descripcion = e.response.data.description || "Error al ofertar";
        if (descripcion.includes('La puja ingresada de forma manual')) {//no había de otra
          this.pushAppMessage({
            show: true,
            type: "info",
            message: descripcion,
          });
        }
        this.$emit("recargarSubasta");
      } finally {
        this.loadingOfertar = false;
        if (digitada) this.montoPuja = null;
      }
    },
    validarPujaDigitada() {
      if (isNaN(this.montoPuja)) {
        this.montoPuja = null;
        return this.pushAppMessage({
          show: true,
          type: "info",
          message: "El monto debe ser un número",
        });
      }
      if (this.montoPuja >= this.totalSubasta) {
        this.montoPuja = null;
        return this.pushAppMessage({
          show: true,
          type: "info",
          message: "La puja no debe ser mayor a la actual puja ganadora",
        });
      }
    },
  },
  props: {
    subastaSeleccionada: {
      type: Object,
    },
    actualGanador: {
      type: Boolean,
      default: false,
    },
    oferta: {
      type: Object,
    },
    loadingOferta: {
      type: Boolean,
    },
    ofertas: {
      type: Array,
    },
    montoInicial: {
      type: Number,
    },
    subastaActivaMejorPuja: {
      type: Number,
    },
    subastaActivaMejorPujaPorcentaje: {
      type: Number,
    },
    totalSubasta: {
      type: Number,
    },
    list_salas: {
      type: Array,
    },
    sala_seleccionada: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.fl-upper::first-letter {
  text-transform: uppercase !important;
}

.w-button-group {
  max-width: max-content;
}

:deep(.v-btn-toggle) {
  flex-direction: column;
}

:deep(.v-btn-toggle--group > .v-btn.v-btn--active) {
  background-color: #404a7a !important;
  color: white !important;
}

.ganadorClass {
  background: #42a5f5;
  color: white !important;
  border-radius: 5px !important;
  padding: 10px;
}

.brake-work {
  word-break: break-word;
}

.digitar_puja {
  width: 50%;
}
</style>
